<template>
<div>
  <v-system-bar
  height="50"
  color="error"
  v-if="isNotProduction"
>
  <label style="color:#fff;text-weight:bold">STAGING!</label>
</v-system-bar>

  <v-app-bar app color="white">



    <v-toolbar elevation="0" flat tile width="100%" transition="slide-x-transition">
      
      
      <v-app-bar-nav-icon @click.stop="toggleSidebar()"></v-app-bar-nav-icon>
      

      <v-btn icon v-if="hasBack" @click="onBack()">
        <v-icon small>mdi-less-than</v-icon>
      </v-btn>

      <slot name="title">
         <v-toolbar-title>
          {{title}}
        </v-toolbar-title>
      </slot>

      <v-tabs v-model="currentTab" :exact="true" v-if="tabs" color="#577399">
        <v-tab v-for="tab in tabs" :key="tab.to" :to="tab.to">{{tab.title}}</v-tab>
      </v-tabs>

      <v-spacer></v-spacer>

      <v-btn :id="btn.id ? btn.id : ''" @click.stop="btn.click ? trigger(btn.click) : ''" class="ml-2" v-for="btn in buttons" :key="btn.label" v-bind="btn">{{btn.label}}</v-btn>

    </v-toolbar>
  </v-app-bar>
</div>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  name: 'AppBar',
  props: {
    back: {
      type: [String, Boolean]
    },
    title: String,
    tabs: Array,
    buttons: Array,
    value: [Number, String],
    click: String,
  },
  computed: {
    // ...mapState(['sidebar']),
    sidebar: {
      get() {
        return this.$store.state.app.sidebar
      },
      set(val) {
        this.$store.commit('TOGGLE_SIDEBAR', val)
      }
    },
    isNotProduction() {
      return process.env.NODE_ENV !== 'production'
    },
    hasBack() {
      if (!this.back) return false
      return true
    },
    drawerStatus() {
      return this.drawer
    },
  },
  data: () => ({
    currentTab: 0
  }),
  mounted() {
    // console.log('back', this.back)
  },
  watch: {
    currentTab(val) {
      this.$emit('input', val)
    }
  },
  methods: {
    onBack() {
      if (typeof this.back == 'boolean') {
        this.$emit('onBack')
      } else if(typeof this.back == 'string') {
        this.$router.push(this.back)
      }
    },
    trigger(eventName) {
      // console.log(eventName)
      this.$emit(eventName)
    },
    toggleSidebar() {
      // this.$root.$emit('toggleSidebar')
      this.sidebar = !this.sidebar
    }
  }
}
</script>